<template>
  <QDialog
    model-value
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <QCard class="column no-wrap scroll-y">
      <QInnerLoading :showing="startingSelection" />
      <QToolbar class="bg-primary text-white">
        <QBtn
          flat
          round
          icon="mdi-close"
          @click="router.push({ name: ROUTES.SELECTION_DASHBOARD })"
        />
        <QToolbarTitle>
          {{ t('Cluster Selection') }}
        </QToolbarTitle>
      </QToolbar>

      <div class="q-mt-lg q-ma-sm">
        <span class="text-weight-bold">
          {{ t('Containers for Selection') }}
        </span>
      </div>

      <QList
        class="col"
        bordered
        separator
      >
        <QItem
          v-for="item in clusterSelectionOrders"
          :key="item.id"
          clickable
          class="list-item-fixed-height"
          :active="item.id === editingSelectionOrder?.id"
          @click="handleItemClick(item)"
        >
          <QItemSection
            class="dense-labels no-wrap"
            top
          >
            <QItemLabel class="row justify-between">
              <span>{{ t('# {text}', { text: item.shipment.externalId }) }}</span>
              <span>{{ item.shipment.customer?.name }}</span>
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <span>
                {{ t('{n} products', item.shipment.items.length) }}
                /
                {{ formatWeight(calculateWeight(item.shipment)) }}
                /
                {{ formatVolume(calculateVolumeForShipment(item.shipment)) }}
              </span>
              <span>{{ item.shipment.carrier?.name }}</span>
            </QItemLabel>
            <QItemLabel class="row justify-between">
              <GraphQLQueryScanField
                v-if="editingSelectionOrder && editingSelectionOrder.id === item.id"
                :initial-value="editingSelectionOrder.container?.barcode"
                class="full-width selected"
                :query="containerQuery"
                :placeholder="t('Container')"
                :rules="[containerMustBeEmptyRule, containerShouldBeUniqueRule(item)]"
                :not-found-message="t('Container not found')"
                @scan="handleContainerScan"
              />
              <template v-else-if="item.container">
                <span>{{ item.container.barcode }}</span>
                <span>{{ item.container.name }}</span>
              </template>
            </QItemLabel>
          </QItemSection>
        </QItem>
      </QList>

      <PrimaryErrorBanner />

      <CreateContainer
        @create="handleContainerScan($event)"
      >
        <template #activator="{ onClick } ">
          <ButtonsRow v-slot="{ buttonProps }">
            <QBtn
              v-bind="buttonProps"
              icon="mdi-autorenew"
              class="col-4"
              @click="clearSelectionOrdersContainers()"
            >
              {{ t('Again') }}
            </QBtn>
            <QBtn
              v-bind="buttonProps"
              icon="mdi-view-grid-plus-outline"
              class="col-4"
              @click="onClick()"
            >
              {{ t('Create Container') }}
            </QBtn>
          </ButtonsRow>
        </template>
      </CreateContainer>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type { Container, CustomerOrder } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import useSelectionStore from '@/stores/selection';
import { storeToRefs } from 'pinia';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import CreateContainer from '@/views/Mobile/CreateContainer.vue';
import GraphQLQueryScanField from '@/components/Mobile/GraphQLQueryScanField.vue';
import { gql } from '@urql/vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import { calculateVolumeForShipment, calculateWeight } from '@/helpers/shipments';
import type { FunctionValidationRule } from '@/types';
import useSpeaker from '@/composables/useSpeaker';
import ROUTES from '@/router/routeNames';
import useErrorHandling from '@/composables/useErrorHandling';

type ClusterSelectionOrder = {
  id: string;
  shipment: CustomerOrder;
  container: Container | null;
}

const { t } = useI18n();

const { formatWeight, formatVolume } = useLocalizedFormatters();

useBreadcrumbs(t('Cluster Selection'));

const { fillErrorsFromGraphQLError, clearErrors, PrimaryErrorBanner } = useErrorHandling();

const store = useSelectionStore();

const router = useRouter();

const { selectedShipments } = storeToRefs(store);

const clusterSelectionOrders = ref<ClusterSelectionOrder[]>(selectedShipments.value
  .map(s => ({
    id:        s.id,
    shipment:  s,
    container: null,
  })));

// eslint-disable-next-line vue/no-ref-object-reactivity-loss
const editingSelectionOrder = ref<ClusterSelectionOrder | null>(clusterSelectionOrders.value[0]);

(function created(): void {
  if (selectedShipments.value.length === 0) {
    router.push({ name: ROUTES.SELECTION_DASHBOARD });
  }
})();

function handleItemClick(selectionOrder: ClusterSelectionOrder): void {
  editingSelectionOrder.value = selectionOrder;
}

const containerQuery = gql`
  query GetContainerForSelection($barcode: String!) {
    containerByBarcode(barcode: $barcode) {
      id
      name
      barcode
      isEmpty
      activeSelections {
        id
      }
    }
  }
`;

const speaker = useSpeaker();

const startingSelection = ref(false);

async function handleContainerScan(container: Container): Promise<void> {
  clearErrors();

  speaker.speak(container.name);
  clusterSelectionOrders.value
    .find(so => so.id === editingSelectionOrder.value!.id)!.container = container;
  const nextEditingSelectionOrder = clusterSelectionOrders.value.find(so => so.container === null);

  if (nextEditingSelectionOrder) {
    editingSelectionOrder.value = nextEditingSelectionOrder;
  } else {
    editingSelectionOrder.value = null;
    startingSelection.value = true;
    const { error } = await store.startSelection(clusterSelectionOrders.value.map(i => ({
      shipmentId:  i.shipment.id,
      containerId: i.container!.id,
    })));
    startingSelection.value = false;
    if (error) {
      fillErrorsFromGraphQLError(error);
    } else {
      await router.push({ name: ROUTES.SELECTION_PROCESS, params: { id: store.currentSelection!.id } });
    }
  }
}

function clearSelectionOrdersContainers(): void {
  clusterSelectionOrders.value = clusterSelectionOrders.value.map(i => ({
    ...i,
    container: null,
  }));
  editingSelectionOrder.value = clusterSelectionOrders.value[0];
}

function containerMustBeEmptyRule(storage: Container): ReturnType<FunctionValidationRule> {
  if (storage.activeSelections.length > 0 || !storage.isEmpty) {
    return t('Container is occupied. Start {operation} in empty Container', { operation: t('Selection') });
  }
  return true;
}

function containerShouldBeUniqueRule(selectionOrder: ClusterSelectionOrder): FunctionValidationRule<Container> {
  return (container: Container) => {
    if (clusterSelectionOrders.value.some(so => so.container?.id === container.id && so.id !== selectionOrder.id)) {
      return t('Container already used by another order');
    }

    return true;
  };
}

</script>

<style lang="scss">

.list-item-fixed-height {
  height: 85px;
}

</style>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/scanning.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/selection.yaml"></i18n>
<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/counts.yaml"></i18n>

<i18n lang="yaml">
ru:
  Containers for Selection: Контейнеры для отбора
  Container already used by another order: Контейнер уже используется для другого заказа
  Create Container: Создать контейнер
  Again: Заново

en:
  Containers for Selection: Containers for Selection
  Container already used by another order: Container already used by another order
  Create Container: Create Container
  Again: Again
</i18n>
