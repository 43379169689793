<template>
  <QExpansionItem>
    <template #header>
      <QItemSection>
        <QItemLabel>{{ queue.printer.displayName }}</QItemLabel>
        <QItemLabel caption>
          {{
            layoutMaxSize === 0
              ? t('Queued tasks: {size}', {size: queue.items.length})
              : t(
                'Queued tasks: {size}/{total} ({pages} p.)',
                {
                  size: currentSize(queue),
                  total: layoutMaxSize,
                  pages: pagesFilled(queue),
                },
              )
          }}
        </QItemLabel>
      </QItemSection>

      <QItemSection side>
        <div v-if="queue.items.length > 0">
          <QBtn
            flat
            round
            icon="mdi-delete-forever"
            color="error"
            :title="t('Clear')"
            :loading="hasProgress('clearing')"
            :disable="hasProgress('printing')"
            @click.stop="clear()"
          />
          <QBtn
            v-if="canPrintWithPrinter(queue.printer)"
            flat
            round
            icon="mdi-printer"
            :title="t('Print all')"
            :loading="hasProgress('printing')"
            :disable="hasProgress('clearing')"
            @click.stop="flush()"
          />
        </div>
      </QItemSection>
    </template>

    <QSeparator />

    <template v-if="editableQueue.items.length > 0">
      <QSelect
        v-if="showPaperLayoutSelection"
        v-model="editableQueue.paperLayout"
        :options="paperLayouts"
        :label="t('Paper Format')"
        :display-value="paperLayoutAsString(editableQueue.paperLayout)"
        emit-value
        filled
        dense
        @update:model-value="save"
      />
      <QItem
        v-for="(item, i) in queue.items"
        :key="listKey(item)"
        clickable
        :to="handleClick(item)"
        :replace="shouldReplace"
      >
        <QItemSection>
          <QItemLabel>
            <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
            {{ i + 1 }}. {{ item.document.title }}
            <template v-if="item.count > 1">
              (x{{ item.count }})
            </template>
            <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
          </QItemLabel>
        </QItemSection>
      </QItem>
    </template>
    <QItem
      v-else
      dense
    >
      <QItemSection>
        <QItemLabel>
          {{ t('Queue is empty') }}
        </QItemLabel>
      </QItemSection>
    </QItem>
  </QExpansionItem>
</template>

<script setup lang="ts">

import useListKeys from '@/composables/useListKeys';
import useProgressHandling from '@/composables/useProgressHandling';
import type {
  CellBarcodePrintDocument,
  ContainerBarcodePrintDocument,
  PrintQueue,
  PrintQueueItem,
  ProductPackBarcodePrintDocument,
} from '@/graphql/types';
import { PrinterPaperLayoutEnum } from '@/graphql/types';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import type { RouteLocationRaw } from 'vue-router';
import ROUTES from '@/router/routeNames';
import { useRoute } from 'vue-router';

const { t } = useI18n();

const { progressStarted, hasProgress } = useProgressHandling<'printing' | 'clearing'>();

const listKey = useListKeys();

const {
  printService,
  paperLayoutAsString,
  paperLayouts,
  showPaperLayoutSelection,
  loadPrinters,
  selectedPrinter,
  canPrintWithPrinter,
} = useDocumentsPrinting();

onMounted(loadPrinters);

const props = defineProps<{
  queue: PrintQueue;
}>();

const editableQueue = ref<PrintQueue>(props.queue);

watch(editableQueue, q => {
  selectedPrinter.value = q.printer;
});

const layoutsMaxSizes = {
  [PrinterPaperLayoutEnum.A4_4x11_GRID]:     44,
  [PrinterPaperLayoutEnum.A4_2x4_GRID]:      8,
  [PrinterPaperLayoutEnum.LABEL_100x148_MM]: 0,
  [PrinterPaperLayoutEnum.LABEL_43x25_MM]:   0,
  [PrinterPaperLayoutEnum.LABEL_40x30_MM]:   0,
};

const layoutMaxSize = computed((): number => {
  return layoutsMaxSizes[editableQueue.value.paperLayout];
});

watch(() => props.queue, function (newValue): void {
  editableQueue.value = newValue;
});

async function save(): Promise<void> {
  await printService.save(editableQueue.value);
}

async function flush(): Promise<void> {
  const done = progressStarted('printing');

  await printService.flush(editableQueue.value);

  done();
}

async function clear(): Promise<void> {
  const done = progressStarted('clearing');

  await printService.clear(editableQueue.value);

  done();
}

function pagesFilled(queue: PrintQueue): number {
  return Math.floor(totalPrintCount(queue) / layoutMaxSize.value);
}

function currentSize(queue: PrintQueue): number {
  return totalPrintCount(queue) - pagesFilled(queue) * layoutMaxSize.value;
}

function totalPrintCount(queue: PrintQueue): number {
  return queue.items.reduce((sum, item) => sum + item.count, 0);
}

const route = useRoute();

const shouldReplace = computed<boolean>(() => {
  const routeNamesForReplace = [ROUTES.PRODUCTS_EDIT, ROUTES.CONTAINERS_EDIT, ROUTES.CELLS_EDIT];
  return routeNamesForReplace.includes((route.name as string));
});

function handleClick(item: PrintQueueItem): null|RouteLocationRaw {
  if (item.document.__typename === 'ProductPackBarcodePrintDocument') {
    return {
      name:   ROUTES.PRODUCTS_EDIT,
      params: { id: (item.document as ProductPackBarcodePrintDocument).pack.product.id },
    };
  }
  if (item.document.__typename === 'ContainerBarcodePrintDocument') {
    return {
      name:   ROUTES.CONTAINERS_EDIT,
      params: { id: (item.document as ContainerBarcodePrintDocument).container.id },
    };
  }
  if (item.document.__typename === 'CellBarcodePrintDocument') {
    return {
      name:   ROUTES.CELLS_EDIT,
      params: { id: (item.document as CellBarcodePrintDocument).cell.id },
    };
  }
  return null;
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Queue is empty: Очередь пуста
  "Queued tasks: {size}": "Заданий в очереди: {size}"
  "Queued tasks: {size}/{total} ({pages} p.)": "Заданий в очереди: {size}/{total} ({pages} стр.)"
  Queue for {printer} is cleared: Очередь печати {printer} очищена
  Clear: Очистить
  Print all: Распечатать все
en:
  Queue is empty: Queue is empty
  "Queued tasks: {size}": "Queued tasks: {size}"
  "Queued tasks: {size}/{total} ({pages} p.)": "Queued tasks: {size}/{total} ({pages} p.)"
  Queue for {printer} is cleared: Queue for {printer} is cleared
  Clear: Clear
  Print all: Print all
</i18n>
